
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";

@Options({
  props: {
    text: String,
    prefix: String,
    suffix: String,
    size: Number,
    maxSize: String,
    showOnMobile: Boolean,
    extraClass: String,
    property: String,
    userId: String,
    schoolAIUserId: String,
    schoolDocId: String,
    classroomDocId: String,
    hover: Boolean,
    copyable: Boolean
  },
  components: {
    MIcon
  }
})
export default class MTableCell extends Vue {
  text = "";
  prefix = "";
  suffix = "";
  size = 0;
  maxSize?: string;
  showOnMobile = false;
  extraClass = "";
  property = "";
  userId = "";
  schoolAIUserId = "";
  schoolDocId = "";
  classroomDocId = "";
  hover?: boolean;
  copyable?: boolean;

  isHovered = false;

  get isOverFlowing() {
    const container = this.$refs.textContainer as HTMLElement;

    return container.scrollWidth > container.clientWidth;
  }

  get tableCellContainer(): HTMLElement | null {
    const tableCellContainer = this.$refs.tableCellContainer as HTMLElement;

    if (!tableCellContainer) {
      return null;
    }

    return tableCellContainer;
  }

  get dynamicClass(): string {
    const classes: string[] = [];
    if (this.size === 0) {
      classes.push("flex-1");
    } else {
      classes.push(`w-${this.size}`);
      classes.push("flex-none");
    }

    if (this.maxSize) {
      classes.push(`max-w-${this.maxSize}`);
    }

    if (this.showOnMobile) {
      classes.push("flex");
    } else {
      classes.push("hidden");
    }
    if (this.extraClass) classes.push(this.extraClass);

    return classes.join(" ");
  }

  redirectToLink() {
    alert("連携しました");
  }

  openSchoolAI() {
    let destinationUrl = "";
    if (
      ["mingaku-admin.web.app", "admin.mingaku.net"].includes(
        window.location.hostname
      )
    ) {
      // PROD
      // destinationUrl = `https://school-ai-system.web.app/${this.schoolAIUserId}`;
      destinationUrl = `https://school-ai-system.web.app/from_mingaku?school_id=${this.schoolDocId}&student_id=${this.schoolAIUserId}`;
    } else if (window.location.hostname === "localhost") {
      // DEV
      // destinationUrl = `http://localhost:3000/${this.schoolAIUserId}`;
      destinationUrl = `http://localhost:3000/from_mingaku?school_id=${this.schoolDocId}&student_id=${this.schoolAIUserId}`;
    } else {
      // STG
      // destinationUrl = `https://school-ai-system-stg.web.app/${this.schoolAIUserId}`;
      destinationUrl = `https://school-ai-system-stg.web.app/from_mingaku?school_id=${this.schoolDocId}&student_id=${this.schoolAIUserId}`;
    }
    window.open(destinationUrl);
  }
  showQandaLog() {
    this.$router.push({ path: `/qanda/${this.userId}` });
  }

  copyText(e: Event) {
    e.preventDefault();
    const target = e.target as HTMLElement | null;
    if (!target) return;
    const textElement = target?.closest("div")?.nextElementSibling as
      | HTMLElement
      | null
      | undefined;
    if (!textElement) return;
    const text = textElement.textContent ?? "";
    if (!navigator.clipboard) {
      alert("このブラウザではコピーできません");
      return;
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("コピーしました");
      })
      .catch(() => {
        () => {
          alert("コピーに失敗しました");
        };
      });
  }

  addCursorClass(e: Event) {
    // JavaScriptでクラスを付けないとCSSだけでは効いてくれない
    const target = e.target as HTMLElement | null;
    if (!target) return;
    target.classList.add("cursor-pointer");
  }

  handleMouseEnterEvent(event: Event) {
    if (!this.hover || !this.isOverFlowing) {
      return;
    }

    this.isHovered = true;
    this.tableCellContainer?.classList.remove("truncate");

    const target = event.target as HTMLElement | null;
    if (!target) return;
    const parentLink = target.closest("a");
    if (parentLink) {
      parentLink.style.pointerEvents = "none";
    }
    const bubble = target.nextElementSibling as HTMLElement | null;
    if (bubble) {
      bubble.style.pointerEvents = "auto";
      bubble.style.cursor = "default";
    }
  }

  handleMouseLeaveEvent(event: Event): void {
    if (!this.hover) {
      return;
    }
    this.isHovered = false;
    this.tableCellContainer?.classList.add("truncate");

    const target = event.target as HTMLElement | null;
    if (!target) return;

    const parentLink = target.closest("a");
    if (parentLink) {
      parentLink.style.pointerEvents = "auto";
    }
  }
}
