import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-4 pt-4 pb-2 flex justify-between items-center border-t z-20 border-gray-200" }
const _hoisted_2 = { class: "sticky left-0 flex justify-start items-center" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "mx-2 text-sm text-gray-700" }
const _hoisted_5 = { class: "flex-1 ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_icon = _resolveComponent("m-icon")
  const _component_m_button = _resolveComponent("m-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_m_button, {
          color: "gray-500",
          text: "",
          onClick: _ctx.back
        }, {
          default: _withCtx(() => [
            _createVNode(_component_m_icon, {
              type: "chevron-left",
              width: "3"
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.start) + "-" + _toDisplayString(_ctx.end) + " / " + _toDisplayString(_ctx.max), 1),
        _createVNode(_component_m_button, {
          color: "gray-500",
          text: "",
          onClick: _ctx.next
        }, {
          default: _withCtx(() => [
            _createVNode(_component_m_icon, {
              type: "chevron-right",
              width: "3"
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}